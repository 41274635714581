import React from 'react'
import PropTypes from 'prop-types'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Label,
  TextBody,
  TextBodyLarge,
  TextBodySmall,
} from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import {
  ListItem,
  OrderedList,
  RichTextMain,
  UnorderedList,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import PageTransitionLink from '../PageTransitionLink'
import InViewSection from '../InViewSection'
import { useStore } from '../../Store'
import Button from '../Button'

const RichText = ({ content, paragraphSize, animate, delay = 0, maxWidth }) => {
  const [store] = useStore()
  const { showPageMask } = store
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
      [MARKS.CODE]: text => <code>{text}</code>,
    },
    renderNode: {
      /*
       ** Blocks
       */

      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children?.toString().trim() === '') return

        return (
          <>
            {paragraphSize === 'large' && (
              <TextBodyLarge maxWidth={maxWidth}>
                <AnimateSplitText animate={animate} delay={delay}>
                  {children}
                </AnimateSplitText>
              </TextBodyLarge>
            )}

            {paragraphSize === 'medium' && (
              <TextBody maxWidth={maxWidth}>
                <AnimateSplitText animate={animate} delay={delay}>
                  {children}
                </AnimateSplitText>
              </TextBody>
            )}

            {paragraphSize === 'small' && (
              <TextBodySmall maxWidth={maxWidth}>
                <AnimateSplitText animate={animate} delay={delay}>
                  {children}
                </AnimateSplitText>
              </TextBodySmall>
            )}
          </>
        )
      },

      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading1 as="h1">
          <AnimateSplitText animate={animate} delay={delay}>
            {children}
          </AnimateSplitText>
        </Heading1>
      ),

      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading2 as="h2">
          <AnimateSplitText animate={animate} delay={delay}>
            {children}
          </AnimateSplitText>
        </Heading2>
      ),

      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading3 as="h3">
          <AnimateSplitText animate={animate} delay={delay}>
            {children}
          </AnimateSplitText>
        </Heading3>
      ),

      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading4 as="h4">
          <AnimateSplitText animate={animate} delay={delay}>
            {children}
          </AnimateSplitText>
        </Heading4>
      ),

      [BLOCKS.HEADING_5]: (node, children) => (
        <Label as="h5">
          <AnimateSplitText animate={animate} delay={delay}>
            {children}
          </AnimateSplitText>
        </Label>
      ),

      [BLOCKS.HEADING_6]: (node, children) => (
        <TextBodyLarge as="h6">
          <AnimateSplitText animate={animate} delay={delay}>
            {children}
          </AnimateSplitText>
        </TextBodyLarge>
      ),

      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),

      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),

      [BLOCKS.LIST_ITEM]: (node, children) => {
        return (
          <InViewSection>
            <ListItem animate={animate && !showPageMask} delay={delay}>
              {children}
            </ListItem>
          </InViewSection>
        )
      },

      [BLOCKS.HR]: () => <hr />,

      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,

      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const { description, gatsbyImageData, file } = node.data.target

        return gatsbyImageData ? (
          <AnimateFadeIn>
            <GatsbyImage image={gatsbyImageData} alt={description} />
          </AnimateFadeIn>
        ) : (
          <AnimateFadeIn>
            <img src={file.url} alt={description} />
          </AnimateFadeIn>
        )
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const block = node.data.target

        switch (block.__typename) {
          case 'ContentfulButton':
            switch (block.link.__typename) {
              case 'ContentfulExternalLink':
                return (
                  <AnimateFadeIn>
                    <Button
                      type="externalLink"
                      href={block.link.url}
                      mobilefill
                    >
                      {block.label}
                    </Button>
                  </AnimateFadeIn>
                )
              case 'ContentfulInternalLink':
                return (
                  <AnimateFadeIn>
                    <Button
                      type="internalLink"
                      to={`${block.link.page.slug}/`}
                      mobilefill
                    >
                      {block.label}
                    </Button>
                  </AnimateFadeIn>
                )
              default:
                return null
            }
          default:
            return null
        }
      },

      /*
       ** Inlines
       */

      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),

      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <a href={node.data.target.file.url}>{children}</a>
      ),

      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <PageTransitionLink to={node.data.target.slug}>
          {children}
        </PageTransitionLink>
      ),

      // [INLINES.EMBEDDED_ENTRY]: (node, children) => {},
    },
    renderText: text =>
      React.Children.toArray(
        text.split('\n').map((t, i) =>
          i > 0 ? (
            <>
              <br />
              {t}
            </>
          ) : (
            t
          )
        )
      ),
  }

  return <RichTextMain>{renderRichText(content, options)}</RichTextMain>
}

RichText.propTypes = {
  content: PropTypes.object,
  delay: PropTypes.number,
  animate: PropTypes.bool,
  paragraphSize: PropTypes.oneOf(['small', 'medium', 'large']),
  maxWidth: PropTypes.number,
}

RichText.defaultProps = {
  paragraphSize: 'medium',
  animate: true,
}

export default RichText
