import styled from 'styled-components'
import { flow } from '../../styles/utils/functions.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'
import { TextBody } from '../../styles/vars/textStyles.style'
import { ButtonMain } from '../Button/index.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const RichTextMain = styled.div`
  ${flow(1.1)}

  strong,
  em,
  u,
  a {
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }

  ${ButtonMain},
  ${PageTransitionLinkMain} {
    text-decoration: none;
  }

  .gatsby-image-wrapper {
    width: max(60%, 32.7rem);

    * + &,
    + * {
      margin-top: 4rem;
    }
  }
`

export const UnorderedList = styled.ul`
  list-style: none;
`

export const OrderedList = styled.ol`
  counter-reset: ol;
  list-style: none;

  :before {
    counter-reset: ol;
    position: absolute;
    left: 0;
    display: block;
    overflow: hidden;
    opacity: ${props => (props.inView && props.animate ? 1 : 0)};
    transition: opacity 0.3s ${easings.inOut.default};
    transition-delay: ${props => `${props.delay + 0.2}s`};
  }

  ul & {
    padding-left: 0.75em;

    :before {
      content: '';
      top: 0.6em;
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background-color: currentColor;
    }
  }

  ol & {
    padding-left: 1.1em;

    :before {
      content: counter(ol) '.';
      top: 0;
      font-weight: ${font.primary.weight.semibold};
    }
  }
`

export const ListItem = styled.li`
  ${TextBody} {
    position: relative;

    :before {
      counter-increment: ol;
      position: absolute;
      left: 0;
      display: block;
      overflow: hidden;
      opacity: ${props => (props.inView && props.animate ? 1 : 0)};
      transition: opacity 0.3s ${easings.inOut.default};
      transition-delay: ${props => `${props.delay + 0.2}s`};
    }

    ul & {
      padding-left: 0.75em;

      :before {
        content: '';
        top: 0.6em;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        background-color: currentColor;
      }
    }

    ol & {
      padding-left: 1.1em;

      :before {
        content: counter(ol) '.';
        top: 0;
        font-weight: ${font.primary.weight.semibold};
      }
    }
  }

  & + & {
    margin-top: 0.5em;
  }
`
